
import {Component, Vue} from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import IPasswordForm from '@/types/IPasswordForm';
import ResetPasswordForm from '@/components/Profile/ResetPasswordForm.vue';

@Component({
  name: 'ResetPassword',
  components: {ResetPasswordForm},
})
export default class ResetPassword extends Vue {
  async resetPassword(data: IPasswordForm, token: string = this.$route.params.token) {
    await AuthService.resetPassword(data, token);
    await this.$router.push({name: 'Login'});
  }
}
